import React from "react";
import { Link } from "gatsby";

import LogoMark from "@svg/logomark.svg";
import Logo from "@svg/logo-white.svg";

import { ModuleWrapper } from "../moduleWrapper";
import { PortableTextBlock } from "@components/sanity/portableTextBlock";
import EnquireModal from "../../enquireModal";

const FooterCTA = ({ config, text, bgImage, hideIcon }) => {
  console.log(bgImage);
  return (
    <ModuleWrapper {...config} className="relative">
      <footer
        style={{ maxHeight: "802px" }}
        className="grid grid-cols-14 lg:grid-rows-1 items-center"
      >
        {/* Text Card */}
        <div className="my-12 bg-red-new text-white col-start-2 col-end-14 rounded-xl text-center pb-6 pt-16 row-start-1 row-end-3 relative z-10 lg:col-end-7 lg:row-start-1 lg:row-end-3 lg:flex lg:flex-col lg:justify-center lg:py-32 px-8 xl:mr-16">
          {!hideIcon && (
            <LogoMark className="w-24 mx-auto mb-one fill-current" />
          )}
          {text && (
            <PortableTextBlock
              white
              text={text}
              className="text-3xl md:text-6xl mb-6"
            />
          )}
          <div className="flex flex-col 2xl:flex-row gap-y-6 gap-x-4 items-center justify-center">
            <EnquireModal>
              <span className="btn-light-red">Enquire Now</span>
            </EnquireModal>
            <a
              href="https://www.cardinallogistics.co.nz/"
              target="_blank"
              rel="noreferrer"
              className="btn-light-red"
            >
              Visit our Website
            </a>
          </div>
          <div className=" opacity-50 text-center mt-12">
            <nav className="flex gap-x-4 justify-center uppercase text-xs font-x-bold">
              <a href="/">Privacy Policy</a>
              <a href="/">Terms & Conditions</a>
            </nav>
            <p className="text-xs mt-half">
              COPYRIGHT &copy; 2022 CARDINAL LOGISTICS
            </p>
          </div>
        </div>
        {/* Bg Image */}
        <div className="relative h-full overflow-hidden col-start-1 col-end-15 row-start-2 row-end-5 lg:row-start-1 lg:row-end-4 lg:self-stretch lg:h-auto flex items-center ">
          <div className="w-full h-full aspect-w-1 aspect-h-1 relative md:aspect-w-5 md:aspect-h-4 lg:aspect-none lg:h-full lg:relative lg:w-full ">
            {bgImage?.asset?.url && (
              <img
                style={{ width: "100%" }}
                loading="lazy"
                src={bgImage?.asset?.url}
                className="absolute w-full h-full object-cover lg:h-full  top-0 left-0 "
              />
            )}
          </div>
          <div className="absolute top-0 left-0 w-full h-full bg-black bg-opacity-20" />
        </div>
        <Link
          to="/"
          className="w-48 mx-auto col-start-1 col-end-15 row-start-4 self-end relative z-20 lg:row-start-1 lg:col-end-14 lg:mr-0 lg:w-60 py-16"
        >
          <Logo />
        </Link>
      </footer>
    </ModuleWrapper>
  );
};

export default FooterCTA;
